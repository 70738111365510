.demos-container {
    background-color: #101010;
    text-align: center;
    margin-top: 10vh;
    height: 100%;
    overflow: hidden;
}

.demos-container > h1 {
    color: #ffffff;
    margin-bottom: 50px;
}

.submit-btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: #ffffff;
    color: #242424;
    border: 1px solid #ffffff;
}

.text-input {
    width: 310px;
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: #101010;
    color: #ffffff;
    border: 1px solid #ffffff;
}

.response {
    width: 400px;
    height: 500px;
    margin: auto;
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    background-color: #101010;
    color: #ffffff;
    border: 1px solid #ffffff;
    overflow: auto;
    text-align: left;
    font-size: small;
}


@media screen and (max-width: 500px) {
    .text-input {
        width: 210px;
    }
    .response {
        width: 300px;
    }

}