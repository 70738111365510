canvas {
    display: inline-block;
    width: 100Vmin;
    height: 100Vmin;
}

.link {
    margin: 0;
    padding: 0;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.label {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 30Vmin);
    font-size: 3rem;
    font-weight: 100;
    color: #0e9d568d;
    animation: fadeIn 1s;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.label-none {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 30Vmin);
    font-size: 3rem;
    font-weight: 100;
    color: #0e9d568d;
    animation: fadeOut 1s;
    animation-fill-mode: forwards;
}