.photo-container {
	display: flex;
	flex-direction: column;
	margin: 0 10%;
}

.photo-container > a {
    color: black;
    text-decoration: none;
}

.gallery_image {
	width: 100%;
	height: 100%;
    object-fit: cover;
}
