.resume-container {
    padding: 100px 20vw;
}

.resume-container > h1 {
    margin: 5px auto;
    font-size: 24px;
    color: #0e9d55;
}

.resume-container > p {
    margin: 5px 0px;
}

.resume-container > hr {
    width: 60vw;
    margin: 10px 0px;
}

.resume-container > h3 {
    text-align: left;
    margin: 40px 0px 10px 0px;
    color:#0e9d55;
}

.resume-container > h4 {
    text-align: left;
    margin: 0px 50px;
    font-size: 0.8rem;
    color: white;
    font-weight: 100;
}

.resume-container > h5 {
    text-align: left;
    margin: 20px 0px 0px 50px;
    color: white;
}

.short-p {
    display: none;
}


@media screen and (max-width: 1400px) {
    .long-p {
        display: none;
    }
    .short-p {
        display: block;
    }
}

@media screen and (max-width: 800px) {
    .resume-container { 
        padding: 100px 10vw;
    }

    .resume-container > hr {
        width: 80vw;
        margin: 10px 0px;
    }
}