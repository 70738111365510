.bookmark {
    height: 50px;
}

.post-container {
	display: flex;
	flex-direction: column;
	padding: 3%;
	margin: 0 10%;
}

.post-content {
    display: flex;
    flex-direction: row;
}

.post-content p {
    color: #ffffff;
    margin-left: 2%;
    margin-bottom: 2%;
}

.post-content a {
    color: #ffffff;
    margin-left: 2%;
    margin-bottom: 2%;
}

.heading {
    color: #ffffff;
	align-items: center;
    margin-bottom: 2%;
}

.image {
    border-radius: 25px;
	width: 350px;
	height: 350px;
}

@media screen and (max-width: 960px) {
    .post-container {
        padding: 1%;
        margin: 0 5%;
    }

    .post-content {
        flex-direction: column;
    }
    

    .post-content p {
        margin: 2% 0;
    }

    .heading {
        margin-top: 64px;
        text-align: center;
        font-size: 1.5rem;
    }

    .image {
        object-fit: cover;
        display: block;
        margin: 0 auto;
        scale: 1;
    }
}

@media screen and (max-width: 500px) {
    .image {
        margin: 0 auto;
        scale: 0.7;
    }

    .post-content p {
        font-size: 0.8rem;
    }

    .post-content a {
        font-size: 0.7rem;
    }
}

@media screen and (max-width: 360px) {
    .image {
        display: none;
    }

    .post-content a {
        display: none;
    }
}