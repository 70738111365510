* {
    /* Font */
    font-family: 'Fragment Mono';

    /* Margins */
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    
    /* Hide scrollbar (IE, Edge, Firefox) */
    -ms-overflow-style: none;
    scrollbar-width: none; 
}

/* Hide scrollbar (Chrome, Safari, Opera) */
-webkit-scrollbar {
    display: none;
}

p {
    font-size: 1rem;
    color: #ffffff;
}

h1 {
    font-size: 2rem;
    color: #ffffff;
    font-weight: 100;
}

h2 {
    font-size: 1.5rem;
    color: #ffffff;
}