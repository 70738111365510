.contact-container {
    background-color: #101010;
    text-align: center;
    margin-top: 10vh;
    height: 100%;
    overflow: hidden;
}

.contact-container > h1 {
    margin-bottom: 50px;
}

.contact-container > a {
    font-size: 0.8rem;
    margin-top: 10px;
    text-decoration: none;
}

.footer-wrap {
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}