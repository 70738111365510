:root {
    --primary: #ffffff
}

.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #ffffff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--outline--highlight {
    background-color: transparent;
    color: #ffffff;
    padding: 8px 20px;
    border: 3px solid #0e9d55;
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 1rem;
}

.btn--large {
    padding: 12px 26px;
    font-size: 1rem;
}

.btn--medium:hover {
    background: #ffffff;
    color: #242424;
    transition: all 0.3s ease-out;
}

.btn--large:hover {
    background: #ffffff;
    color: #242424;
    transition: all 0.3s ease-out;
}