.home-container {
    background-color: #101010;
    background: transparent;
    text-align: center;
    height: 100%;
}

.canvas-container {
    position: absolute;
    transform: translate(0, -100%);
    height: 100Vmin;
    left: 50%;
}

.canvas {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 50%);
}

.canvas-fade {
    position: absolute;
    z-index: 1;
    pointer-events: none;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: linear-gradient(#00000000,#10101000,#101010ea, #101010);
}

.home-container > h1 {
    position: relative;
    z-index: 2;
    pointer-events: none;
    color: #ffffff;
    font-size: 5rem;
    font-weight: 100;
    margin-top: 400px;
}

.home-container > h2 {
    position: relative;
    z-index: 2;
    pointer-events: none;
    color: #ffffff;
    font-size: 2rem;
    margin-top: 10px;
    font-weight: 100;
}

.home-btns {
    position: relative;
    z-index: 2;
    margin-top: 32px;
}

.home-btns .btn {
    position: relative;
    z-index: 2;
    margin: 6px;
}

.home-bullets {
    position: relative;
    z-index: 2;
    pointer-events: none;
    text-align: center;
    width: 500px; 
    margin: 0 auto;
    margin-top: 5vh;
    margin-bottom: 10vh;
}

.home-bullets > p {
    margin-top: 10px;
    margin-left: 10px;
    text-align: left;
}

.showcase-img {
    transition: .5s;
    border-radius: 10px;
    width: 100Vmin;
    height: 50Vmin;
    
}

.showcase-img:hover{
    transition: .5s;
    box-shadow: 0px 0px 10px #0e9d55;
}

.itch-frame {
    border-radius: 10px;
    width: 100Vmin;
    height: 20Vmin;
    margin-top: 10vh;
    border: transparent;
}

.itch-frame:hover {
    transition: .5s;
    box-shadow: 0px 0px 10px #0e9d55;
}


@media screen and (max-width: 800px) {
    .home-container > h1 {
        font-size: 3rem;
    }

    .home-container > h2 {
        font-size: 1.5rem;
    }

    .home-bullets {
        width: max-content;
    }
    
    .home-bullets > p {
        font-size: 0.75rem;
    }
}